import moment from "moment";
import axios from "axios";
const mutations = {
  setData(state, data) {
    state[data.type] = data.data;
  },
};
const actions = {
  setBanner({ commit }, data) {
    commit("setData", { type: "banners", data: data });
  },
  resetGlobal({ commit, state }) {
    Object.keys(state).forEach((e) => {
      commit("setData", {
        type: e,
        data: null,
      });
    });
  },
  getZipCode({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.zipCode) {
        resolve(state.zipCode);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "STATE-TOWN-ZIP",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "zipCode",
            data: result.data,
          });
          resolve(result.data);
        });
    });
  },
  getCountryList({ rootState }, icon_key) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/cms", {
          version: rootState.userData.version,
          os: rootState.userData.os,
          code: "ICO01",
          lang: rootState.selectedLang,
          icon_key: icon_key,
          is_grouping: true,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getProductList({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/cms", {
          version: rootState.userData.version,
          os: rootState.userData.os,
          code: "ICO01",
          lang: rootState.selectedLang,
          icon_key: data.icon_key,
          countryID: data.countryID,
          destinationOperatorID: data.destinationOperatorID,
          is_grouping: true,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getBillerList({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/cms", {
          version: rootState.userData.version,
          os: rootState.userData.os,
          code: "ICO01",
          lang: rootState.selectedLang,
          icon_key: data.icon_key,
          countryID: data.countryID,
          is_grouping: true,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  /* eslint-disable */
  getOperatorId({ rootState }, mobile_number) {
    return new Promise((resolve) => {
      axios
        .get(
          `https://mw-stg.gobiz.com.my/DTone/api/mobileNo?mobile_number=${mobile_number}`
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getIATProduct({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post(`/cms`, {
          version: rootState.userData.version,
          os: rootState.userData.os,
          code: "ICO01",
          lang: rootState.selectedLang,
          icon_key: "PrepaidTopupIAT",
          countryID: data.countryID,
          destinationOperatorID: data.destinationOperatorID,
          is_grouping: true,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  checkoutINT({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post(`/ttReserveAndSimulate`, {
          version: rootState.userData.version,
          os: rootState.userData.os,
          lang: rootState.selectedLang,
          userID: rootState.userData.userID,
          productCode: data.productCode,
          destMSISDN: data.destMSISDN,
          recipientMSISDN: data.recipientMSISDN,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getCountryCode({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.countryCode) {
        resolve(state.countryCode);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "IR502_CTR",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "countryCode",
            data: result.data,
          });
          resolve(result.data);
        });
    });
  },
  getBank({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (
        state.bank &&
        Object.keys(state.bank).length > 0 &&
        moment(state.getBankTime).diff(moment(), "minutes") <= 10
      ) {
        resolve(state.bank);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          os: rootState.userData.os,
          sqlId: "AG019_BNK_CDE",
          userID: rootState.userData.userID,
        })
        .then((result) => {
          commit("setData", {
            type: "bank",
            data: result.data,
          });
          commit("setData", {
            type: "getBankTime",
            data: new Date(),
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getGender({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.gender) {
        resolve(state.gender);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_GENDR",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "gender",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getState({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.state) {
        resolve(state.state);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "STATE-CODE",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "state",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getTown({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.town) {
        resolve(state.town);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "STATE-TOWN",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "town",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getBusinessOrSelf({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.business_self) {
        resolve(state.business_self);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "IR052_PST_HELD",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "business_self",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getNewID({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.newid) {
        resolve(state.newid);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_NEW_ID_IND",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "newid",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getNRICType({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.nricType) {
        resolve(state.nricType);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_NRIC_TYPE",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "nricType",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getIDType({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.idType) {
        resolve(state.idType);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "ID_TYP_SIGN_UP",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "idType",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getOccupation({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.occupation) {
        resolve(state.occupation);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_WORK_NATURE",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "occupation",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getPosition({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.position) {
        resolve(state.position);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AP001_POSITION",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "position",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getIncomeList({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.income) {
        resolve(state.income);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_INCOME_RANGE",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "income",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getTransferPurpose({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.transferPurpose) {
        resolve(state.transferPurpose);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "CW_TRANSFER_PURPOSE",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "transferPurpose",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getCurrency({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.countryCurrency) {
        resolve(state.countryCurrency);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_ISO_CURRENCY_CDE",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "countryCurrency",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getWalletSize({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.walletSize) {
        resolve(state.walletSize);
        return;
      }
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_WALLET_SIZE",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "walletSize",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  gethomeBanners({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.homeBanner) {
        resolve(state.homeBanner);
        return;
      }
      rootState.apiaxios
        .post("/cms", {
          code: "ICO03",
          lang: rootState.selectedLang,
          is_grouping: true,
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "homeBanner",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getWealthE({ rootState,  commit }) {
    return new Promise((resolve) => {
        rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_SOW_E",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "wealth",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getWealthS({ rootState, commit }) {
    return new Promise((resolve) => {
        rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_SOW_S",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "wealth",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getWealthU({ rootState, commit }) {
    return new Promise((resolve) => {
        rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "AG001_SOW_U",
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "wealth",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getFundsE({ rootState,  commit }) {
    return new Promise((resolve) => {
      rootState.apiaxios
      .post("/dataEnq", {
        userID: rootState.userData.userID,
        sqlID: "AG001_SOF_E",
        os: rootState.userData.os,
      })
        .then((result) => {
          commit("setData", {
            type: "funds",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getFundsS({ rootState,commit }) {
    return new Promise((resolve) => {
      rootState.apiaxios
      .post("/dataEnq", {
        userID: rootState.userData.userID,
        sqlID: "AG001_SOF_S",
        os: rootState.userData.os,
      })
        .then((result) => {
          commit("setData", {
            type: "funds",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getFundsU({ rootState, commit }) {
    return new Promise((resolve) => {
      rootState.apiaxios
      .post("/dataEnq", {
        userID: rootState.userData.userID,
        sqlID: "AG001_SOF_U",
        os: rootState.userData.os,
      })
        .then((result) => {
          commit("setData", {
            type: "funds",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  gethomeServices({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.homeServices) {
        resolve(state.homeServices);
        return;
      }
      rootState.apiaxios
        .post("/cms", {
          code: "ICO10",
          lang: rootState.selectedLang,
          is_grouping: true,
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "homeServices",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getGetCMS({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/cms", {
          code: "ICO01",
          lang: rootState.selectedLang,
          is_grouping: true,
          os: rootState.userData.os,
          icon_key: data,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getAnnouncement({ rootState, state }) {
    return new Promise((resolve) => {
      if (state.announcementFetched) {
        resolve({ data: [] });
      }
      rootState.apiaxios
        .post("/cms", {
          code: "ICO02",
          lang: "en",
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getRegisterSuccess({ rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/cms", {
          code: "ICO14",
          lang: rootState.selectedLang,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getRegisterBanner({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (
        state.registerBanner &&
        Object.keys(state.registerBanner).length > 0
      ) {
        resolve(state.registerBanner);
        return;
      }
      rootState.apiaxios
        .post("/cms", {
          code: "UCO07",
          os: rootState.os,
          lang: rootState.selectedLang,
        })
        .then((result) => {
          commit("setData", {
            type: "registerBanner",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  shownAlert({ commit }) {
    commit("setData", {
      type: "shownAlerts",
      data: true,
    });
  },
  getGSHR({ rootState }, type) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/gshr", {
          userID: rootState.userData.userID,
          partnerCode: type,
          os: rootState.userData.os,
          ageIndicator:rootState.login.profile.ageIndicator,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getAPI({ rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/cms", {
          version: rootState.userData.version,
          os:  rootState.userData.os,
          code: "ICO01",
          userid: rootState.userData.userID,
          icon_key: "TransportFrictionlessParking",
          lang: rootState.selectedLang,
          ageIndicator:rootState.login.profile.ageIndicator,
          is_grouping: true
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getWalletIcon({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (state.walletIcon && Object.keys(state.walletIcon).length > 0) {
        resolve(state.walletIcon);
        return;
      }
      rootState.apiaxios
        .post("/cms", {
          code: "ICO05",
          lang: "en",
          is_grouping: true,
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "walletIcon",
            data: result.data,
          });
          resolve(result.data);
        });
    });
  },
  getTrxLmt({ rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "CNTCL_LMT",
          os: rootState.userData.os,
        })
        .then((result) => {
          resolve(result.data);
        });
    });
  },
  getCTrxLmt({ rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          sqlID: "DLY_CMLT_LMT",
          os: rootState.userData.os,
        })
        .then((result) => {
          resolve(result.data);
        });
    });
  },
  getTNCData({ rootState }, data) {
    return new Promise((resolve) => {
      data.os = rootState.userData.os;
      data.version = rootState.userData.version;
      data.lang = rootState.selectedLang;
      data.code = "ICO06";
      data.is_grouping = true;
      rootState.apiaxios
        .post("/cms", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
};
const state = () => {
  return {
    countryCode: null,
    walletSize: null,
    gender: null,
    newid: null,
    idType: null,
    countryCurrency: null,
    nricType: null,
    homeBanner: null,
    registerBanner: null,
    homeServices: null,
    occupation: null,
    position: null,
    income: null,
    state: null,
    funds:null,
    wealth:null,
    town: null,
    transferPurpose: null,
    business_self: null,
    announcementFetched: false,
    announcementStatus: false,
    shownAlerts: false,
    banners: [],
    bank: {},
    walletIcon: {},
    getBankTime: new Date("1970-01-01Z00:00:00:000"),
    customGlobalErrorRoute: null,
    clearGoPIN: false,
    insufficientError: false,
    qrPayAvailable: false,
  };
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
