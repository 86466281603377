import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/exit",
    name: "exit",
    component: () =>
    import(/* webpackChunkName: "home" */ "../views/SSO/ExitView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
    import(/* webpackChunkName: "login" */ "../views/SSO/LoginView.vue"),
  },
  {
    path: "/login/password/:validated?",
    name: "login2",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/SSO/LoginView2.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Shared/ContactUs.vue"),
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () =>
      import(
        /* webpackChunkName: "register" */
        "../views/SSO/ForgotPassword.vue"
      ),
  },
  {
    path: "/forgotpassword/2",
    name: "forgotpassword2",
    component: () =>
      import(
        /* webpackChunkName: "register" */
        "../views/SSO/ForgotPassword2.vue"
      ),
  },
  {
    path: "/forgotpassword/success",
    name: "forgotpasswordSucess",
    component: () =>
      import(
        /* webpackChunkName: "register" */
        "../views/SSO/ForgotPasswordSuccess.vue"
      ),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */
        "../views/SSO/RegisterView.vue"
      ),
  },
  {
    path: "/register/2",
    name: "register2",
    component: () =>
      import(
        /* webpackChunkName: "register" */
        "../views/SSO/RegisterView2.vue"
      ),
  },
  {
    path: "/register/3",
    name: "register3",
    component: () =>
      import(
        /* webpackChunkName: "register" */
        "../views/SSO/RegisterView3.vue"
      ),
  },
  {
    path: "/register/success",
    name: "registersuccess",
    component: () =>
      import(
        /* webpackChunkName: "register" */
        "../views/SSO/RegisterView4.vue"
      ),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/SSO/ProfileView.vue"),
  },
  {
    path: "/gopin/password/:path?",
    name: "gopinpass",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/SSO/LoginView2.vue"),
  },
  {
    path: "/gopin/new",
    name: "gopincreate",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/ChangeGoPIN.vue"),
  },
  {
    path: "/gopin/edit/:path?",
    name: "gopinedit",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/ChangeGoPIN.vue"),
  },
  {
    path: "/gopin/success/:path?",
    name: "gopinsuccess",
    component: () =>
      import(
        /* webpackChunkName: "gopin" */
        "../views/SSO/ChangeGoPINSuccess.vue"
      ),
  },
  {
    path: "/wallet/sendmoney/:tag",
    name: "sendmoney",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/MoneyTransfer.vue"
      ),
  },
  {
    path: "/wallet/requestmoney/:tag",
    name: "requestmoney",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/MoneyTransfer.vue"
      ),
  },
  {
    path: "/transactions",
    name: "transactions",
    component: () =>
      import(
        /* webpackChunkName: "transaction" */
        "../views/Transactions/TransactionHistory.vue"
      ),
  },
  {
    path: "/profile/password",
    name: "confirmpass",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/SSO/LoginView2.vue"),
  },
  {
    path: "/profile/password/change",
    name: "changepass",
    component: () =>
      import(
        /* webpackChunkName: "profile" */
        "../views/SSO/ChangePassword.vue"
      ),
  },
  {
    path: "/profile/password/complete",
    name: "changepassresult",
    component: () =>
      import(
        /* webpackChunkName: "profile" */
        "../views/SSO/ChangePassResult.vue"
      ),
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/Wallet/WalletView.vue"),
  },
  {
    path: "/wallet/topup/:tag?",
    name: "wallettopup",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/Wallet/TopupView.vue"),
  },
  {
    path: "/device/gopin",
    name: "gopindevice",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/profile/device",
    name: "mydevice",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/SSO/MyDevice.vue"),
  },
  {
    path: "/invalid",
    name: "invalid",
    component: () =>
      import(/* webpackChunkName: "invalid" */ "../views/SSO/InvalidHash.vue"),
  },
  {
    path: "/prepaid/:type",
    name: "TabPrepaidTopUp",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/TopUp.vue"),
  },
  {
    path: "/iat/:type",
    name: "PrepaidTopupIAT",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/IATView.vue"),
  },
  
  {
    path: "/bill/:type",
    name: "TabBillPayment",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/BillView.vue"),
  },
  {
    path: "/bill/:type",
    name: "ElectricityIntBillPayment",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/ForeignBillView.vue"),
  },
  {
    path: "/bill/:type",
    name: "InternetTelekomMalaysia",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/BillView.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabGame",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/TopUp.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabMusicAndAudio",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/TopUp.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabPrepaidTV",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/TopUp.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabPhoneInternet",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/BillView.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabInternationalServices",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/BillView.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabInternationalDirectDial",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/TopUp.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabInternet",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/TopUp.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabElectricity",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/BillView.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabWater",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/BillView.vue"),
  },
  {
    path: "/bill/:type",
    name: "TabTV",
    component: () =>
      import(/* webpackChunkName: "topup" */ "../views/TopUp/BillView.vue"),
  },
  {
    path: "/insurance/:type",
    name: "life_insurance_screen",
    component: () =>
      import(
        /* webpackChunkName: "topup" */
        "../views/Insurance/LlifeInsuranceScreen.vue"
      ),
  }, 
  {
    path: "/insurance/:type",//personal
    name: "CHUBBPA",
    component: () =>
      import(
        /* webpackChunkName: "insurance" */
        "../views/Insurance/PersonalInsuranceScreen.vue"
      ),
  },
  {
    path: "/insurance/:type",
    name: "travel_insurance_screen",
    component: () =>
      import(
        /* webpackChunkName: "topup" */
        "../views/Insurance/TravelInsuranceScreen.vue"
      ),
  },
  {
    path: "/api/:type",
    name: "API",
    component: () =>
      import(
        /* webpackChunkName: "redirector" */
        "../views/Redirector/GSHRRedirectorView.vue"
      ),
  },
  {
    path: "/gshr/:type",
    name: "GSHR",
    component: () =>
      import(
        /* webpackChunkName: "redirector" */
        "../views/Redirector/GSHRRedirectorView.vue"
      ),
  },
  {
    path: "/list/:tab",
    name: "List",
    component: () =>
      import(/* webpackChunkName: "list" */ "../views/Shared/ListView.vue"),
  },
  {
    path: "/profile/edit",
    name: "editProfile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/SSO/EditProfile.vue"),
  },
  {
    path: "/profile/updateDoc/:age?",
    name: "updateDoc",
    component: () =>
      import(
        /* webpackChunkName: "profile" */
        "../views/SSO/UpdateDocument.vue"
      ),
  },
  {
    path: "/device/gopin",
    name: "gopindevice",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/send/otp",
    name: "sendMoneyOTP",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/receive/otp",
    name: "receiveMoneyOTP",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/send/success:trxReferenceNo?",
    name: "sendSuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/SSO/SendMoneySuccess.vue"
      ),
  },
  {
    path: "/wallet/request/success:trxReferenceNo?",
    name: "requestSuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/SSO/RequestMoneySuccess.vue"
      ),
  },
  {
    path: "/bannerdetail/:id",
    name: "bannerdetail",
    component: () =>
      import(
        /* webpackChunkName: "profile" */
        "../views/BannerDetail/BannerDetailScreen.vue"
      ),
  },
  {
    path: "/profile/changeNumber",
    name: "ChangeNumber",
    component: () =>
      import(
        /* webpackChunkName: "register" */
        "../views/SSO/ChangeNumber.vue"
      ),
  },
  {
    path: "/profile/device/delete/gopin",
    name: "pinDeleteDevices",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/profile/changeNumber/success",
    name: "changeNumberSuccess",
    component: () =>
      import(
        /* webpackChunkName: "profile" */
        "../views/SSO/ChangeNumberSuccess.vue"
      ),
  },
  {
    path: "/profile/edit/gopin",
    name: "editProfPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/profile/edit/success",
    name: "ChangeProfileSuccess",
    component: () =>
      import(
        /* webpackChunkName: "profile" */
        "../views/SSO/ChangeProfileSuccess.vue"
      ),
  },
  {
    path: "/wallet/list",
    name: "card",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/Wallet/WalletList.vue"),
  },
  {
    path: "/wallet/detail",
    name: "cardDetail",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletDetail.vue"
      ),
  },
  {
    path: "/wallet/replacement",
    name: "cardreplacement",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletReplacement.vue"
      ),
  },
  {
    path: "/wallet/replacement/success",
    name: "cardreplacementsuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletReplacementSuccess.vue"
      ),
  },
  {
    path: "/wallet/block",
    name: "walletblock",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletBlock.vue"
      ),
  },
  {
    path: "/wallet/unblock/confirmation",
    name: "walletunblockconfirmation",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletUnblockConfirmation.vue"
      ),
  },
  {
    path: "/wallet/block/confirmation",
    name: "walletblockconfirmation",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletBlockConfirmation.vue"
      ),
  },
  {
    path: "/wallet/card/walletCvvView",
    name: "walletCvvView",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/Card/WalletCvvView.vue"
      ),
  },
  {
    path: "/wallet/unblock/success",
    name: "walletunblocksuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletUnblockSuccess.vue"
      ),
  },
  {
    path: "/wallet/block/success",
    name: "walletblocksuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletBlockSuccess.vue"
      ),
  },
  {
    path: "/wallet/card/addCard",
    name: "addcard",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/Card/AddCard.vue"
      ),
  },
  {
    path: "/wallet/block/physical/apply",
    name: "walletblockapplyphysicalcard",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletBlockApplyPhysicalCard.vue"
      ),
  },
  {
    path: "/wallet/block/physical/apply/fee",
    name: "walletblockapplyphysicalcardfee",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletBlockApplyPhysicalCardFee.vue"
      ),
  },
  {
    path: "/wallet/physical/apply",
    name: "applyphysicalcard",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/ApplyPhysicalCard.vue"
      ),
  },
  {
    path: "/wallet/physical/apply/fee",
    name: "applyphysicalcardfee",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/ApplyPhysicalCardFee.vue"
      ),
  },
  {
    path: "/wallet/pin/:tag?",
    name: "walletpin",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/Wallet/WalletPIN.vue"),
  },
  {
    path: "/wallet/cardUsage/:hash",
    name: "cardUsage",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/Card/CardUsageView.vue"
      ),
  },
  {
    path: "/wallet/card/cardUsage/cardUsagePin",
    name: "cardUsagePin",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/Card/CardUsagePin.vue"
      ),
  },
  {
    path: "/wallet/card/setcardpin",
    name: "setcardpin",
    component: () =>
      import(
        /* webpackChunkName: "gopin" */
        "../views/Wallet/Card/SetCardPin.vue"
      ),
  },
  {
    path: "/wallet/card/setcardpin/success",
    name: "setcardpinsuccess",
    component: () =>
      import(
        /* webpackChunkName: "gopin" */
        "../views/Wallet/Card/SetCardPinSuccess.vue"
      ),
  },
  {
    path: "/wallet/apply",
    name: "walletapply",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletApplyNewCard.vue"
      ),
  },
  {
    path: "/wallet/apply/detail",
    name: "walletapplydetail",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletApplyDetail.vue"
      ),
  },
  {
    path: "/wallet/apply/address",
    name: "walletapplyaddress",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletApplyAddress.vue"
      ),
  },
  {
    path: "/wallet/apply/physical",
    name: "walletapplyphysical",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletApplyPhysical.vue"
      ),
  },
  {
    path: "/wallet/apply/physical/success",
    name: "walletapplyphysicalsuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletApplyPhysicalSuccess.vue"
      ),
  },
  {
    path: "/wallet/new/apply/success",
    name: "walletnewapplysuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletApplyNewCardSuccess.vue"
      ),
  },
  {
    path: "/wallet/primarycard/success",
    name: "walletprimarycardsuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletPrimaryCardSuccess.vue"
      ),
  },
  {
    path: "/wallet/add/:tag?",
    name: "walletaddcard",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletAddCard.vue"
      ),
  },
  {
    path: "/wallet/gopayz/add/detail/:tag?",
    name: "walletgopayzaddcarddetail",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletAddGoPayzCardDetail.vue"
      ),
  },
  {
    path: "/wallet/gopayz/add/address/:tag?",
    name: "walletgopayzaddcardaddress",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletAddCardAddress.vue"
      ),
  },
  {
    path: "/wallet/remove/card/success",
    name: "walletremovecardsuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletRemoveCardSuccess.vue"
      ),
  },
  {
    path: "/wallet/other/add/detail/:tag?",
    name: "walletotheraddcarddetail",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletAddOtherCardDetail.vue"
      ),
  },
  {
    path: "/wallet/add/success/:tag?",
    name: "walletaddnewcardsuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletAddCardSuccess.vue"
      ),
  },
  {
    path: "/wallet/activate",
    name: "walletactivate",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletActivatePhysicalCard.vue"
      ),
  },
  {
    path: "/wallet/activate/success",
    name: "walletactivatesuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletActivatePhysicalCardSuccess.vue"
      ),
  },
  {
    path: "/wallet/validation",
    name: "walletpasswordvalidation",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletPasswordValidation.vue"
      ),
  },
  {
    path: "/wallet/pin/set/:tag?",
    name: "walletsetpin",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletSetPIN.vue"
      ),
  },
  {
    path: "/wallet/pin/set/confirmation/:tag?",
    name: "walletsetpinconfirmation",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletSetPINConfirmation.vue"
      ),
  },
  {
    path: "/wallet/pin/set/success/:tag?",
    name: "walletsetpinsuccess",
    component: () =>
      import(
        /* webpackChunkName: "wallet" */
        "../views/Wallet/WalletSetPINSuccess.vue"
      ),
  },
  {
    path: "/wallet/closure/gopin",
    name: "walletClosurePin",
    component: () =>
      import(/* webpackChunkName: "walletclosure" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/topup/channel/:tag?",
    name: "WalletTopupChannel",
    component: () =>
      import(
        /* webpackChunkName: "WalletTopupChannel" */
        "../views/Wallet/TopupChannel.vue"
      ),
  },
  {
    path: "/login/setupGoPin",
    name: "setupGoPin",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/SSO/ChangeGoPIN.vue"),
  },
  {
    path: "/disputeRequest",
    name: "disputeRequest",
    component: () =>
      import(
        /* webpackChunkName: "disputeRequest" */
        "../views/DisputeRequest.vue"
      ),
  },
  {
    path: "/disputeRequest/success",
    name: "disputeRequestSuccess",
    component: () =>
      import(
        /* webpackChunkName: "disputeRequest" */
        "../views/DisputeRequestSuccess.vue"
      ),
  },
  {
    path: "/device/gopin",
    name: "sendMoneyPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/device/gopin",
    name: "sendMoneyFromRequested",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/transfer/send/success",
    name: "sendMoneySuccess",
    component: () =>
      import(
        /* webpackChunkName: "sendMoney" */
        "../views/SSO/TransferSendMoneySuccess.vue"
      ),
  },
  {
    path: "/transfer/reject/success",
    name: "rejectMoneySuccess",
    component: () =>
      import(
        /* webpackChunkName: "sendMoney" */
        "../views/SSO/RejectMoneySuccess.vue"
      ),
  },
  {
    path: "/wallet/closure",
    name: "walletClosure",
    component: () =>
      import(
        /* webpackChunkName: "walletclosure" */
        "../views/Wallet/WalletClosureRequest.vue"
      ),
  },
  {
    path: "/wallet/closure/gopin",
    name: "walletClosurePin",
    component: () =>
      import(/* webpackChunkName: "walletclosure" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/closure/success",
    name: "walletClosureSuccess",
    component: () =>
      import(
        /* webpackChunkName: "walletclosure" */
        "../views/Wallet/WalletClosureSuccess.vue"
      ),
  },
  {
    path: "/profile/updateDoc/gopin",
    name: "UpdateDocGoPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/profile/updateDoc/success",
    name: "UpdateDocSuccess",
    component: () =>
      import(
        /* webpackChunkName: "updateDoc" */
        "../views/SSO/SubmissionSuccess.vue"
      ),
  },
  {
    path: "/register/submissionSuccess",
    name: "SubmissionSuccess",
    component: () =>
      import(
        /* webpackChunkName: "updateDoc" */
        "../views/SSO/OnboardSuccess.vue"
      ),
  },
  {
    path: "/register/submissionUnSuccess",
    name: "SubmissionUnSuccess",
    component: () =>
      import(
        /* webpackChunkName: "updateDoc" */
        "../views/SSO/SubmissionUnSuccess.vue"
      ),
  },
  {
    path: "/wallet/transfer/bank",
    name: "TransferBank",
    component: () =>
      import(
        /* webpackChunkName: "transferBank" */
        "../views/Wallet/TransferToBank/IndexView.vue"
      ),
  },
  {
    path: "/wallet/transfer/bank/detail",
    name: "TransferBankForm",
    component: () =>
      import(
        /* webpackChunkName: "transferBank" */
        "../views/Wallet/TransferToBank/FormView.vue"
      ),
  },
  {
    path: "/wallet/transfer/bank/gopin",
    name: "TransferBankGoPIN",
    component: () =>
      import(/* webpackChunkName: "transferBank" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/transfer/bank/success",
    name: "TransferBankSuccess",
    component: () =>
      import(
        /* webpackChunkName: "transferBank" */
        "../views/Wallet/TransferToBank/SuccessView.vue"
      ),
  },
  {
    path: "/topup/complete/:path?",
    name: "TopupComplete",
    component: () =>
      import(
        /* webpackChunkName: "transferBank" */
        "../views/Wallet/TopupComplete.vue"
      ),
  },
  {
    path: "/payment/comfirmation",
    name: "PaymentConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        "../views/Payment/ConfirmationView.vue"
      ),
  },
  {
    path: "/payment",
    name: "Payment",
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        "../views/Payment/PaymentView.vue"
      ),
  },
  {
    path: "/payment/gopin",
    name: "paymentForeignBillGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/payment/gopin",
    name: "paymentIATGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/payment/gopin",
    name: "paymentGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/payment/success",
    name: "paymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        "../views/Payment/PaymentSuccess.vue"
      ),
  },
  {
    path: "/payment/failed",
    name: "paymentFailed",
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        "../views/Payment/PaymentFailed.vue"
      ),
  },
  {
    path: "/QRpayment/success",
    name: "qrPaymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        "../views/Payment/QRPaymentSuccess.vue"
      ),
  },
  {
    path: "/QRpayment/failed",
    name: "qrPaymentFailed",
    component: () =>
      import(
        /* webpackChunkName: "payment" */
        "../views/Payment/QRPaymentFail.vue"
      ),
  },
  {
    path: "/referrer",
    name: "referrer",
    component: () =>
      import(/* webpackChunkName: "sso" */ "../views/SSO/ReferFriend.vue"),
  },
  {
    path: "/wallet/upgrade",
    name: "upgradeWallet",
    component: () =>
      import(/* webpackChunkName: "sso" */ "../views/Wallet/WalletUpgrade.vue"),
  },
  {
    path: "/wallet/upgrade",
    name: "upgradeWalletFormView",
    component: () =>
      import(
        /* webpackChunkName: "sso" */
        "../views/Wallet/Upgrade/FormView.vue"
      ),
  },
  {
    path: "/wallet/gopin",
    name: "walletRemoveCardGoPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "setOnUsPrimaryCardGoPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "setOffUsPrimaryCardGoPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "setOnUsPrimaryQRCardGoPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/upgrade/gopin/:walletLimit?",
    name: "UpgradeWalletPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/upgrade/success/:walletLimit?",
    name: "upgradeWalletSuccess",
    component: () =>
      import(
        /* webpackChunkName: "gopin" */
        "../views/Wallet/Upgrade/UpgradeSuccess.vue"
      ),
  },
  {
    path: "/wallet/detail/gopin",
    name: "walletDetailGoPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/qrpay",
    name: "qrPay",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/Wallet/QRPayment.vue"),
  },
  {
    path: "/qrpay/gopin",
    name: "QRPaymentPIN",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/cardUsage/gopin",
    name: "CardUsageGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "walletAddNewGoPayzCardGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin/:tag?",
    name: "walletAddNewOtherCardGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "walletUnblockCardConfirmationGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "walletApplyNewCardGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/replacement/physical/apply",
    name: "WalletReplacementCardWithPhyisicalCard",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/Wallet/WalletReplacementCardWithPhyisicalCard.vue"),
  },
  {
    path: "/wallet/replacement/physical/apply/fee",
    name: "WalletReplacementCardWithPhyisicalCardFee",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/Wallet/WalletReplacementCardWithPhyisicalCardFee.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "ReplacementCardWithPhyisicalCardGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "walletReplacementCardGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "walletClosureRequestGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "walletBlockCardGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "walletBlockCardWithPhysicalGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/gopin",
    name: "walletApplyPhysicalCardGoPin",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/wallet/cardUsage/:hash/success",
    name: "PreferenceUpdateSuccess",
    component: () =>
      import(
        /* webpackChunkName: "gopin" */
        "../views/Wallet/Card/CardUsageSuccess.vue"
      ),
  },
  {
    path: '/verify/user',
    name: 'verifyUser',
    component: () => import(
      /* webpackChunkName: "profile" */
      "../views/SSO/VerifyUser.vue")
  },
  {
    path: '/manageRecurring',
    name: 'ManageRecurring',
    component: () => import(
      /* webpackChunkName: "recurring" */
      "../views/Recurring/ManageRecurringScreen.vue")
  },
  {
    path: '/manageRecurring/detail',
    name: 'ManageRecurringDetail',
    component: () => import(
      /* webpackChunkName: "recurring" */
      "../views/Recurring/ManageRecurringDetailScreen.vue")
  },
  {
    path: "/manageRecurring/delete/gopin",
    name: "GopinManageRecurringDelete",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/manageRecurring/update/gopin",
    name: "GopinManageRecurringUpdate",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: "/manageRecurring/add/gopin",
    name: "GopinManageRecurringAdd",
    component: () =>
      import(/* webpackChunkName: "gopin" */ "../views/SSO/GoPin.vue"),
  },
  {
    path: '/setupRecurring',
    name: 'SetupRecurring',
    component: () => import(
      /* webpackChunkName: "setupRecurring" */
      "../views/Recurring/SetupRecurringScreen.vue")
  },
  {
    path: "/setupRecurring/success",
    name: "SetupRecurringSuccess",
    component: () =>
      import(
        /* webpackChunkName: "setupRecurringSuccess" */
        "../views/Recurring/SetupRecurringSuccess.vue"
      ),
  },
  {
    path: "/manageRecurring/success",
    name: "ManageRecurringSuccess",
    component: () =>
      import(
        /* webpackChunkName: "manageRecurringSuccess" */
        "../views/Recurring/ManageRecurringSuccess.vue"
      ),
  },
  {
    path: "/manageRecurring/failed",
    name: "ManageRecurringFailed",
    component: () =>
      import(
        /* webpackChunkName: "manageRecurringFailed" */
        "../views/Recurring/ManageRecurringFailed.vue"
      ),
  },
  {
    path: '/manageFavourite',
    name: 'ManageFavourite',
    component: () => import(
      /* webpackChunkName: "favourite" */
      "../views/Favourite/ManageFavouriteScreen.vue")
  },
  {
    path: '/manageFavourite/detail',
    name: 'ManageFavouriteDetail',
    component: () => import(
      /* webpackChunkName: "favourite" */
      "../views/Favourite/ManageFavouriteDetailScreen.vue")
  },
  {
    path: "/manageFavourite/success",
    name: "ManageFavouriteSuccess",
    component: () =>
      import(
        /* webpackChunkName: "manageFavouriteSuccess" */
        "../views/Favourite/ManageFavouriteSuccess.vue"
      ),
  },
  {
    path: "/selectFromFavourite",
    name: "SelectFromFavourite",
    component: () =>
      import(
        /* webpackChunkName: "selectFromFavourite" */
        "../views/Favourite/SelectFromFavouriteScreen.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;