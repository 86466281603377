// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
// Vuetify
import { createVuetify } from "vuetify";

const customTheme = {
  colors: {
    error: "#ff0000",
  },
};

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: "customTheme",
      themes: {
        customTheme,
      },
    },
    icons: {
      iconFont: "mdiSvg",
    },
  }
);
