import moment from "moment";
const mutations = {
  setData(state, data) {
    state[data.type] = data.data;
  },
};
const actions = {
  cardCvv2Enquiry({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/cardCvv2Enquiry", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  setForm({ commit }, data) {
    commit("setData", {
      type: "paymentForm",
      data: data,
    });
  },
  setResponse({ commit }, data) {
    commit("setData", {
      type: "apiResponse",
      data: data,
    });
  },
  setPaymentCache({ commit }, data) {
    commit("setData", {
      type: "paymentCache",
      data: data,
    });
    commit("setData", {
      type: "appliedPromo",
      data: {}
    })
    commit("setData", {
      type: "promoResponse",
      data: {}
    })
    commit("setData", {
      type: "redeemPointEstimation",
      data: {}
    })
    commit("setData", {
      type: "redeemPointRequest",
      data: {}
    })
  },
  setPrepaid({ commit }, data) {
    commit("setData", {
      type: "selectedPrepaid",
      data: data,
    });
  },
  resetPromos({ state, commit, dispatch }) {
    if (Object.keys(state.appliedPromo).length > 0) {
      let form = { ...state.appliedPromo };
      form.action = "R";
      dispatch("applyInHousePromoCode", form);
    } else {
      commit("setData", {
        type: "promoResponse",
        data: {},
      });
    }
    commit("setData", {
      type: "sunLifeResponse",
      data: {},
    });
  },
  getAmountToPoint({ rootState, commit }, data) {
    return new Promise((resolve) => {
      commit("setData", {
        type: "redeemPointRequest",
        data: data,
      });
      rootState.apiaxios
        .post("/amountToPoint", {
          os: rootState.userData.os,
          version: rootState.userData.version,
          userID: rootState.userData.userID,
          trxReferenceNo: data.refNo,
          point: data.point.toFixed(2),
        })
        .then((result) => {
          commit("setData", {
            type: "redeemPointEstimation",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  INTPayment({ rootState, commit }, data) {
    return new Promise((resolve) => {
      data.userID = rootState.userData.userID;
      data.os = rootState.userData.os;
      rootState.apiaxios
        .post("/ttTopUp", data)
        .then((result) => {
          commit("setData", {
            type: "paymentResult",
            data: result.data ?? null,
          });
          resolve(result.data);
        })
        .catch((error) => {
          commit("setData", {
            type: "paymentResult",
            data: error.response.data ?? null,
          });
          resolve(error.response.data);
        });
    });
  },
  qrPaymentNotification({ rootState, commit }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/paymentnotification", {
          os: rootState.userData.os,
          version: rootState.userData.version,
          userID: rootState.userData.userID,
          dateFrom: data.dateFrom,
          dateTo: data.dateTo,
        })
        .then((result) => {
          commit("setData", {
            type: "QrPaymentResult",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          commit("setData", {  
            type: "QrPaymentResult",
            data: error.response.data,
          });
          resolve(error.response.data);
        });
    });
  },
  mobilityonePurchase({ rootState, commit }, data) {
    return new Promise((resolve) => {
      data.userID = rootState.userData.userID;
      data.os = rootState.userData.os;
      rootState.apiaxios
        .post("/mobilityonePurchase", data)
        .then((result) => {
          commit("setData", {
            type: "paymentResult",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          commit("setData", {
            type: "paymentResult",
            data: error.response.data,
          });
          resolve(error.response.data);
        });
    });
  },
  getAvailPromoCodeList({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/getAvailPromoCodeList", {
          os: rootState.userData.os,
          version: rootState.userData.version,
          userID: rootState.userData.userID,
          trxReferenceNo: data.refNo,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  applyInHousePromoCode({ rootState, commit }, data) {
    return new Promise((resolve) => {
      let form = { ...data };
      form.os = rootState.userData.os;
      form.userID = rootState.userData.userID;
      rootState.apiaxios
        .post("/applyInHousePromoCode", form)
        .then((result) => {
          if (form.action === "R") {
            commit("setData", {
              type: "appliedPromo",
              data: {},
            });
            commit("setData", {
              type: "promoResponse",
              data: {},
            });
          } else {
            commit("setData", {
              type: "appliedPromo",
              data: form,
            });
            commit("setData", {
              type: "promoResponse",
              data: result.data,
            });
          }
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  sunlifeapplypromocode({ rootState, commit }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/sunlifeapplypromocode", {
          os: rootState.userData.os,
          version: rootState.userData.version,
          userID: rootState.userData.userID,
          trxReferenceNo: data.refno,
          promoCode: data.promoCode,
        })
        .then((result) => {
          commit("setData", {
            type: "sunLifeResponse",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  amountToPoint({ rootState, commit }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/amountToPoint", {
          os: rootState.userData.os,
          version: rootState.userData.version,
          userID: rootState.userData.userID,
          sqlId: "AM131_FPX_BNK_CDE",
        })
        .then((result) => {
          commit("setData", {
            type: "bank",
            data: result.data,
          });
          commit("setData", {
            type: "getBankTime",
            data: new Date(),
          });
          resolve(result);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getOnlineBank({ rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/dataEnq", {
          userID: rootState.userData.userID,
          os: rootState.userData.os,
          version: rootState.userData.version,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getCard({ rootState, state, commit }) {
    return new Promise((resolve) => {
      if (
        state.card &&
        Object.keys(state.card).length > 0 &&
        moment(state.getCardTime).diff(moment(), "minutes") <= 1
      ) {
        resolve(state.card);
        return;
      }
      rootState.apiaxios
        .post("/cardEnquiry", {
          os: rootState.userData.os,
          userID: rootState.userData.userID,
          version: rootState.userData.version,
        })
        .then((result) => {
          commit("setData", {
            type: "card",
            data: result.data,
          });
          commit("setData", {
            type: "getCardTime",
            data: new Date(),
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getBank({ rootState, commit }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/dataEnq", {
          os: rootState.userData.os,
          userID: rootState.userData.userID,
          sqlId: "AM131_FPX_BNK_CDE",
        })
        .then((result) => {
          commit("setData", {
            type: "bank",
            data: result.data,
          });
          resolve(result.data);
        });
    });
  },
  addStandingInstruction({ rootState }, data) {
    return new Promise((resolve) => {
      let form = { ...data };
      form.os = rootState.userData.os;
      form.userID = rootState.userData.userID;
      rootState.apiaxios
        .post("/addStandingInstruction", form)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
};
const state = () => {
  return {
    wallet: {},
    walletGetTime: new Date("1970-01-01Z00:00:00:000"),
    card: {},
    getCardTime: new Date("1970-01-01Z00:00:00:000"),
    bank: {},
    apiResponse: {},
    paymentCache: [],
    promoResponse: {},
    sunLifeResponse: {},
    redeemPointEstimation: {},
    redeemPointRequest: {},
    paymentForm: {},
    paymentResult: {},
    appliedPromo: {},
    QrPaymentResult:{}
  };
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};