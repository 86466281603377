const mutations = {
  setData(state, data) {
    state[data.type] = data.data;
  },
};
const actions = {
  signupVerify({ rootState, commit }, phoneNumber) {
    return new Promise((resolve) => {
      if (state.registeringPhone && !phoneNumber) {
        phoneNumber = state.registeringPhone;
      }
      commit("setData", {
        type: "registeringPhone",
        data: phoneNumber,
      });
      rootState.apiaxios
        .post("/signUpVerification", {
          os: rootState.userData.os,
          userID: phoneNumber,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  sendOTP({ rootState, commit }, data) {
    if (state.registeringPhone && !data.userID) {
      data.userID = state.registeringPhone;
    }
    if (!data.callFrom) {
      data.callFrom = "ONBOARD";
    }
    if (!data.mobileNo) {
      data.mobileNo = data.userID;
    }
    data.os = rootState.userData.os;
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/sendOTP", data)
        .then((result) => {
          commit("setData", {
            type: "apiResponse",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getRegistration({commit},data){
    commit("setData", {
      type: "registrationResponse",
      data: data.data,
    });
  },
  validateOTP({ rootState, state, dispatch }, otp) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/signUpVerificationConf", {
          userID: otp.phone,
          mobileNo: otp.phone,
          callFrom: otp.callFrom,
          os: rootState.userData.os,
          OTP: otp.otp,
          messageReferenceNo: state.apiResponse.messageReferenceNo,
        })
        .then((result) => {
          dispatch("getRegistration", {
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  verifyOTP({ rootState, state, dispatch }, data) {
    return new Promise((resolve) => {
      data.messageReferenceNo = state.apiResponse.messageReferenceNo;
      data.os = rootState.userData.os;
      rootState.apiaxios
        .post("/verifyOTP", data)
        .then((result) => {
          dispatch("getRegistration", {
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  changeMobileNoConf({ rootState, state }, data) {
    return new Promise((resolve) => {
      data.messageReferenceNo = state.apiResponse.messageReferenceNo;
      data.uniqueRef = rootState.login.loginUser.uniqueRef;
      data.os = rootState.userData.os;
      rootState.apiaxios
        .post("/changeMobileNoConf ", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  passwordSet({ rootState, state, commit }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/custRegistration1", {
          userID: state.registeringPhone,
          mobileNo: state.registeringPhone,
          callFrom: "ONBOARD",
          os: rootState.userData.os,
          password: data.password,
          confirmPassword: data.confirmPassword,
          registrationRef: state.registrationResponse.registrationRef,
        })
        .then((result) => {
          commit("setData", {
            type: "custRegistration",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  saveRegistrationForm({ rootState, state, commit }, data) {
    return new Promise((resolve) => {
      data.userID = state.registeringPhone;
      data.mobileNo = state.registeringPhone;
      data.callFrom = "ONBOARD";
      data.os = rootState.userData.os;
      data.registrationRef = state.registrationResponse.registrationRef;
      rootState.apiaxios
        .post("/custRegistration2", data)
        .then((result) => {
          commit("setData", {
            type: "custRegistration",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  completeRegistration({ rootState, state }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/custRegistration3", {
          userID: state.registeringPhone,
          callFrom: "ONBOARD",
          os: rootState.userData.os,
          registrationRef: state.registrationResponse.registrationRef,
          campaignCode: data.campaignCode,
          referralCode: data.referralCode,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  setupGoPin({ commit }, data) {
    commit("setData", {
      type: "registeringPhone",
      data: data.phoneNumber,
    });
    commit("setData", {
      type: "goPinRef",
      data: data.ref,
    });
  },
  saveGoPin({ rootState, state }, data) {
    return new Promise((resolve) => {
      data.uniqueRef = state.goPinRef;
      data.os = rootState.userData.os;
      data.userID = state.registeringPhone;
      rootState.apiaxios
        .post("/setupMPIN", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  campaignPlanLst({ rootState }) {
    return new Promise((resolve) => {
      let data = {};
      data.os = rootState.userData.os;
      data.userID = rootState.userData.userID;
      rootState.apiaxios
        .post("/campaignPlanLst", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  walletUpgrade({ rootState, state }, MPIN) {
    return new Promise((resolve) => {
      let data = { ...state.upgradeWallet };
      data.os = rootState.userData.os;
      data.MPIN = MPIN;
      data.userID = rootState.userData.userID;
      rootState.apiaxios
        .post("/walletUpgrade", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  walletUpgradeEligibility({ rootState }) {
    return new Promise((resolve) => {
      let data = {};
      data.os = rootState.userData.os;
      data.userID = rootState.userData.userID;
      rootState.apiaxios
        .post("/walletUpgradeEligibility", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  cacheUpgradeWallet({ commit }, data) {
    commit("setData", {
      type: "upgradeWallet",
      data: data,
    });
  },
  uploadUpgradeDocs({ rootState }, data) {
    return new Promise((resolve) => {
      data.os = rootState.userData.os;
      data.userID = rootState.userData.userID;
      rootState.apiaxios
        .post("/internaluploadFileConsumerMultiple", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
};
const state = () => {
  return {
    apiResponse: {},
    registrationResponse: {},
    custRegistration: {},
    registeringPhone: null,
    goPinRef: null,
    upgradeWallet: null,
    upgradeFile: null,
  };
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
