const mutations = {
    setData(state, data) {
        state[data.type] = data.data;
    },
    SET_SELECTED_FAV_DATA(state, payload) {
        state.selected_fav_data = payload;
    },
    SET_SELECTED_FAV_RESULT(state, payload) {
        state.selected_fav_result = payload;
    },
    SET_FAV_PARAMS(state, payload) {
        state.favParams = payload;
    },
    SET_SELECTED_TAB_DATA(state, payload) {
        state.selected_tab_data = payload;
    },
    SET_FAV_LIST_STATE(state, payload) {
        state.fav_list_state = payload;
    }
}
const actions = {
    favouriteEnquiry({ rootState }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios.post("/favouriteEnq", {
                os: rootState.userData.os,
                version: rootState.userData.version,
                userID: rootState.userData.userID,
                serviceCode: data.serviceCode,
                productGrp: data.iconKey,
            }).then((result) => {
                resolve(result.data)
            }).catch((error) => {
                resolve(error.response.data);
            })
        })
    },
    deleteFavourite({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/deleteFavourite", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    editFavourite({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/editFavourite", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    addFavourite({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/addFavourite", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
}
const state = () => {
    return {
        selected_fav_data: null,
        selected_fav_result: null,
        favParams: {},
        selected_tab_data: null,
        fav_list_state: null,
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}