<template>
  <v-dialog aria-labelledby="dialog"
    max-width="350px"
    :modelValue="modelValue"
    @update:modelValue="
      emitEvent($event);
      $emit('close');
    "
    :persistent="persistent"
  >
    <v-card v-if="lang" class="curved-dialog px-1">
      <v-card-title class="px-5">
        <v-row class="mt-0 mx-0">
          <v-col
            v-if="!title"
            style=" font-size: 24px;"
            class="d-flex align-center font-weight-bold px-0"
          >
            {{ oops ? lang.oopsTitle : emptyHeaderTitle ? "" : lang.message }}
          </v-col>
          <v-col v-else class="d-flex align-center font-weight-bold px-0">
            {{ title }}
          </v-col>
          <v-col class="text-end align-center px-0" v-if="closeIcon">
            <v-btn
              aria-label="close"
              width="25px"
              height="25px"
              icon
              flat
              @click="
                emitEvent(false);
                $emit('close');
              "
            >
              <v-icon size="small" style="font-weight: 400"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-img
      v-if="imageSrc!=''"
      :src="imageSrc"
      :lazy-src="imageSrc"
      cover
      ></v-img>
      <v-card-text
        class="px-5 pb-3"
        style="
          font-weight: 500;
          color: black;
          font-size: 18px;
          letter-spacing: normal;
          white-space: pre-line;
        "
      >
        <slot>
          {{ message }}
        </slot>
      </v-card-text>
      <v-card-actions v-if="showButton" :class="`${bottomSpace ? 'pb-8' : 'pb-3'} px-5`">
        <slot name="actions">
          <v-btn
            flat
            aria-label="close"
            v-if="buttonClose"
            style=" font-size: 24px;"
            class="secondary-btn"
            @click="
              emitEvent(false);
              $emit('ok');
            "
          >
            {{ lang.close_btn }}
          </v-btn>
          <v-btn
            flat
            aria-label="upgrade"
            v-else-if="!ok && !logout"
            class="secondary-btn"
            style=" font-size: 24px;"
            @click="redirectStore();
              emitEvent(false);
              $emit('ok');
            "
          >
            {{ lang.WalletUpgradeButton  }}
          </v-btn>
          <v-btn
            flat
            aria-label="okay"
            v-else-if="ok && !logout"
            class="secondary-btn"
            style=" font-size: 24px;"
            @click="
              emitEvent(false);
              $emit('ok');
            "
          >
            {{ lang.okButton }}
          </v-btn>
          <v-btn
            v-else-if="logout"
            flat
            aria-label="logout"
            class="secondary-btn"
            style=" font-size: 24px;"
            :to="{ name: 'login' }"
            @click="
              emitEvent(false);
              $emit('logout');
            "
          >
            {{ lang.logOutButton }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    showButton:{
      type: Boolean,
      default: () => true,
    },
    modelValue: {
      type: Boolean,
      default: () => false,
    },
    imageSrc:{
      type: String,
      default: () => '',
    },
    message: {
      type: String,
      default: () => '',
    },
    oops: {
      type: Boolean,
      default: () => false,
    },
    ok: {
      type: Boolean,
      default: () => false,
    },
    closeIcon: {
      type: Boolean,
      default: () => true,
    },
    logout: {
      type: Boolean,
      default: () => false,
    },
    persistent: {
      type: Boolean,
      default: () => false,
    },
    emptyHeaderTitle: {
      type: Boolean,
      default: () => false,
    },
    buttonClose: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
    },
    bottomSpace: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ["update:modelValue", "ok", "close"],
  computed: {
    isIOS(){
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent)) {
         return true
      } else {
         return false
      }
    },
    isHuawei(){
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/huawei/i.test(userAgent)) {
        return true
      } else {
         return false
      }
    },
    lang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    redirectStore(){
      window.open(encodeURI(this.isIOS ? 'https://apps.apple.com/my/app/gopayz/id1465572076': (this.isHuawei ? 'https://appgallery.huawei.com/app/C102638559': 'https://play.google.com/store/apps/details?id=com.cedarplus.gopayz')),'_blank')
    },
    emitEvent(e) {
      this.$emit("update:modelValue", e);
    },
  },
};
</script>
