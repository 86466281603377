<template>
  <v-overlay
    class="align-center justify-center"
    persistent
    :modelValue="modelValue"
    style="z-index: 99999px; box-shadow: 3px rgba(0, 0, 0, 0.4)"
  >
    <v-img
      src="@/assets/images/loader.gif"
      style="width: 70px; background-color: white; border-radius: 999px"
    ></v-img>
  </v-overlay>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
