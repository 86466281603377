import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import CryptoJS from "crypto-js";
import axios from "axios";
import config from "@/config.json";
import register from "./modules/register.js";
import globalData from "./modules/globalData";
import login from "./modules/login";
import wallet from "./modules/wallet";
import insurance from "./modules/insurance.js";
import payment from "./modules/payment.js";
import topup from "./modules/topup.js";
import voucher from "./modules/voucher.js";
import moment from "moment";
import recurring from "./modules/recurring.js";
import favourite from "./modules/favourite.js";

const vuexLocal = new VuexPersistence({
  key: "AIA",
  saveState: function (key, state) {
    var cloned = JSON.parse(JSON.stringify(state));
    cloned.globalError = null;
    cloned.globalOpsError = null;
    cloned.login.updatingUser = false;
    cloned.permissions = {};
    window.localStorage.setItem(
      key,
      CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(JSON.stringify(cloned)),
        "AIAData"
      )
    );
  },
  restoreState: function (key) {
    if (window.localStorage.getItem(key)) {
      let result = CryptoJS.AES.decrypt(
        window.localStorage.getItem(key),
        "AIAData"
      ).toString(CryptoJS.enc.Utf8);
      let obj = JSON.parse(result);
      if (obj.expiredDate) {
        if (moment().diff(moment(obj.expiredDate), "hour") >= 0) {
          //expired session
          obj.login.profile = {};
          obj.globalData = {
            countryCode: null,
            walletSize: null,
            gender: null,
            newid: null,
            countryCurrency: null,
            nricType: null,
            homeBanner: null,
            homeServices: null,
            occupation: null,
          };
          obj.config = {};
          obj.expiredDate = moment()
            .add(parseFloat(config.expireHours), "hours")
            .toDate();
          this.saveState("AIA", obj);
        }
      }
      return obj;
    }
  },
});

export default createStore({
  state: {
    apiaxios: null,
    userData: {},
    config: {},
    maintenanceError:{},
    globalError: null,
    globalOpsError: null,
    expiredDate: moment().add(parseFloat(config.expireHours), "hour").toDate(),
    lang: null,
    selectedLang: "en",
    drawerMenu: false,
    permissions: {},
    logoutPrompt: false,
    globalErrorCode: null,
    registerError:null,
  },
  getters: {},
  mutations: {
    setData(state, data) {
      state[data.type] = data.data;
    },
    cachePermissions(state, data) {
      state.permissions[data.category] = data.data;
    },
    SET_USERID(state, data) {
      state.userData = { ...state.userData, userID: data };
    },
    SET_USER_DATA(state, data) {
      state.userData = { ...state.userData, ...data };
    },
  },
  actions: {
    getAxiosResponse({ commit, state }, a) {
      a.data.interceptors.request.use(function (config) {
        let str = "";
        Object.keys(config.data).sort().forEach((key) => {
          if (config.data[key] !== undefined && config.data[key] !== null && key !== "tokenHash" ) {
            if (key === "amount" || key === "topUpAmount" || key === "recurMinAmt") {
              str += parseFloat(config.data[key].toString().replace(",", "")).toFixed(2) + "|"
            }
            else{
              str += config.data[key] + "|"
            }
          }
        })
        str += "1DQ3mvuh";
        console.log(str);
        config.headers.TokenHash = CryptoJS.HmacSHA256(str, "D63GmHzw").toString(CryptoJS.enc.Hex);
        return config;
      });
      a.data.interceptors.response.use(
        (configRes) => {
          if (configRes.data.responseCode === "890001") {
            commit("setData", {
              type: "globalErrorCode",
              data: configRes.data.responseCode,
            });
          }
          return configRes;
        },
        (error) => {
          if (error.response.status === 401) {
            commit("setData", { type: "globalErrorCode", data: "890001" });
          }
          if (error.response.data.responseCode === "890001") {
            commit("setData", {
              type: "globalErrorCode",
              data: error.response.data.responseCode,
            });
          }
          return Promise.reject(error);
        }
      );
      commit("setData", {
        type: "apiaxios",
        data: a.data,
      });
      state.apiaxios = a.data;
    },
    initializeAxios({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (!data.partnerid && state.userData.partnerID) {
          data.partnerid = state.userData.partnerID;
        } //#if(close or leave the page, check previous data)
        if (
          Object.keys(state.userData).length > 0 &&
          Object.keys(data).length <= 1
        ) {
          //session by token
          let token = Object.keys(state.login.loginUser).length > 0 ? state.login.loginUser.token : state.userData.token;
          const a = axios.create({
            baseURL: config.apiUrl,
            headers: {
              Authorization: "Bearer " + token,
              Lang: state.selectedLang,
              PartnerID: state.userData.partnerID,
            },
          });
          dispatch('getAxiosResponse', {
            data: a
          });
          resolve(state.userData);
        } else {
          //#first time
          let token = null;
          token = (Object.keys(state.login.loginUser).length > 0) ? state.login.loginUser.token : state.userData.token;
          let headers = {};
          headers.Authorization = token ? "Bearer " + token : '';
          axios
            .get(config.apiUrl + "partnerredirect", {
              params: {
                system: data.system,
                partnerID: data.partnerid,
                payload: data.payload,
                iconkey: data.iconkey,
                page: data.page,
              },
              headers: headers,
            })
            .then((result) => {
              if (result.data.responseCode.includes("4000")) {
                if (
                  (data.system == "GoPayz" &&
                    parseInt(result.data.responseCode) > 400006 &&
                    parseInt(result.data.responseCode) < 400012) ||
                  (parseInt(result.data.responseCode) > 400018 &&
                    parseInt(result.data.responseCode) < 400022)
                ) {
                  result.isSessionExpired = false;
                  commit("setData", {
                    type: "userData",
                    data: result.data,
                  });
                  resolve(result.data);
                } else if (
                  (data.system == "GoPayz" || data.system == "GoBiz") &&
                  parseInt(result.data.responseCode) > 400011 &&
                  parseInt(result.data.responseCode) < 400019
                ) {
                  commit("setData", {
                    type: "userData",
                    data: result.data,
                  });
                  resolve(result.data);
                } else {
                  resolve(result.data);
                }
              }

              if (result.data.responseCode === "400") {
                resolve(result.data);
                return;
              }
              result.data.userID = result.data.userID && result.data.userID.length < 8 ? this.userData.partnerPayload.MSISDN : result.data.userID;
              commit("setData", {
                type: "userData",
                data: result.data,
              });
              const a = axios.create({
                baseURL: config.apiUrl,
                headers: {
                  Authorization: "Bearer " + result.data.token,
                  Lang: state.selectedLang,
                  PartnerID: data.partnerid,
                },
              });
              dispatch('getAxiosResponse', {
                data: a
              });
              resolve(result.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    fetchSystemParams({ state, commit }) {
      let sysParam = state.apiaxios.post("/systemParameter", {
        os: state.userData.os,
        userID: state.userData.userID,
      });
      let lang = state.apiaxios.post("/translation", {
        os: state.userData.os,
        lang: state.selectedLang,
      });
      Promise.all([sysParam, lang]).then((result) => {
        commit("setData", {
          type: "config",
          data: result[0].data,
        });
        commit("setData", {
          type: "lang",
          data: result[1].data,
        });
      });
    },
    changeLanguage({ commit, state }, lang) {
      return new Promise((resolve) => {
        commit("setData", {
          type: "selectedLang",
          data: lang,
        });
        state.apiaxios.defaults.headers.Lang = lang;
        axios
          .post(config.apiUrl + "translation", {
            os: state.userData.os,
            lang: lang,
          })
          .then((result) => {
            commit("setData", {
              type: "lang",
              data: result.data,
            });
            resolve();
          })
          .catch((error) => {
            resolve(error.response.data);
          });
      });
    },
    setMaintenance({ commit }, error) {
      commit("setData", {
        type: "maintenanceError",
        data: error,
      });
    },
    setGlobalError({ commit }, error) {
      commit("setData", {
        type: "globalError",
        data: error,
      });
    },
    setRegisterError({ commit }, error) {
      commit("setData", {
        type: "registerError",
        data: error,
      });
    },
    globalErrorCode({ commit }, error) {
      commit("setData", {
        type: "globalErrorCode",
        data: error,
      });
    },
    setGlobalPayloadError({ commit }, error) {
      commit("setData", {
        type: "globalPayloadError",
        data: error,
      });
    },
    setGlobalOpsError({ commit }, error) {
      commit("setData", {
        type: "globalOpsError",
        data: error,
      });
    },
    getPagePermission({ state, commit }, category) {
      return new Promise((resolve) => {
        if (!state.permissions[category]) {
          state.apiaxios
            .post("page", {
              category: category,
            })
            .then((result) => {
              resolve(result.data);
              commit("cachePermissions", {
                category: category,
                data: result.data,
              });
            })
            .catch((err) => {
              resolve(err.data.response);
            });
        } else {
          resolve(state.permissions[category]);
        }
      });
    },
    disputeTransaction({ state }, data) {
      return new Promise((resolve) => {
        data.userID = state.userData.userID;
        data.os = state.userData.os;
        state.apiaxios
          .post("disputeTransaction", data)
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            resolve(error.response.data);
          });
      });
    },
  },
  modules: {
    register,
    globalData,
    login,
    wallet,
    insurance,
    payment,
    topup,
    voucher,
    recurring,
    favourite,
  },
  plugins: [vuexLocal.plugin],
});
