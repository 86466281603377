import moment from "moment";
const mutations = {
    setData(state, data) {
        state[data.type] = data.data;
    }
}
const actions = {
    personalbanner({ rootState, commit, state }) {
        return new Promise((resolve) => {
            if (state.personalBanner && Object.keys(state.personalBanner).length > 0 && moment(state.personalBannerCache).diff(moment(), 'minutes') <= 1) {
                resolve(state.personalBanner);
                return;
            }
            rootState.apiaxios.post("/cms", {
                code: 'ICO07',
                icon_key: 'FinancialServicesChubbPA',
                is_grouping: true,
                os: rootState.userData.os
            }).then((result) => {
                commit('setData', {
                    type: 'personalBanner',
                    data: result.data
                });
                commit('setData', {
                    type: 'personalBannerCache',
                    data: new Date()
                });
                resolve(result.data);
            }).catch((error) => {
                resolve(error.response.data);
            })
        })
    }
}
const state = () => {
    return {
        personalBanner: null,
        personalBannerCache: new Date('1970-01-01Z00:00:00:000'),
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}