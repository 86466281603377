import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import CryptoJS from "crypto-js";
import SHA256 from "crypto-js/sha256";
import ScrollPicker from "vue3-scroll-picker";
import "core-js";

loadFonts();
const firebaseConfig = {
    apiKey: "AIzaSyAdGmh0KAibIwJGA1DVYsHARbmlmeQwPiM",
    authDomain: "um-gopayz.firebaseapp.com",
    projectId: "um-gopayz",
    storageBucket: "um-gopayz.appspot.com",
    messagingSenderId: "873281218931",
    appId: "1:873281218931:web:cffe73e2634a5edd68fd06",
    measurementId: "G-NHC49VD4MR",
};


// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
createApp(App)
    .provide('analytics', analytics)
    .provide('encrypt', function (text, token) {
        const hash = SHA256(token).toString(CryptoJS.enc.Hex);
        const key = CryptoJS.enc.Utf8.parse(hash.slice(0, 16));
        const iv = CryptoJS.enc.Utf8.parse(hash.slice(-16));
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            keySize: 128,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Hex.stringify(encrypted.ciphertext);
    })
    .provide('decrypt', function (text, token) {
        const hash = SHA256(token).toString(CryptoJS.enc.Hex);
        const key = CryptoJS.enc.Utf8.parse(hash.slice(0, 16));
        const iv = CryptoJS.enc.Utf8.parse(hash.slice(-16));
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(text) }, key, {
            keySize: 128,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Utf8.stringify(decrypted);
    })
    .use(router)
    .use(store)
    .use(vuetify)
    .use(ScrollPicker)
    .mount('#app')
