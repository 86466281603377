const mutations = {
    setData(state, data) {
        state[data.type] = data.data;
    }
}
const actions = {
    myVoucherView({ rootState }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios.post("/myVoucherView", {
                os: rootState.userData.os,
                version: rootState.userData.version,
                userID: rootState.userData.userID,
                voucherGUID: data.voucherGUID,
            }).then((result) => {
                resolve(result.data)
            }).catch((error) => {
                resolve(error.response.data);
            })
        })
    },
    myVoucherUpdateR({ rootState }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios.post("/myVoucherUpdate", {
                os: rootState.userData.os,
                version: rootState.userData.version,
                userID: rootState.userData.userID,
                voucherGUID: data.voucherGUID,
                typ: "R"
            }).then((result) => {
                resolve(result.data)
            }).catch((error) => {
                resolve(error.response.data);
            })
        })
    },
    myVoucherUpdateS({ rootState }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios.post("/myVoucherUpdate", {
                os: rootState.userData.os,
                version: rootState.userData.version,
                userID: rootState.userData.userID,
                voucherGUID: data.voucherGUID,
                typ: "S"
            }).then((result) => {
                resolve(result.data)
            }).catch((error) => {
                resolve(error.response.data);
            })
        })
    },
    getVoucherList({ rootState, commit }) {
        return new Promise((resolve) => {
            rootState.apiaxios.post("/myVoucherList", {
                os: rootState.userData.os,
                version: rootState.userData.version,
                userID: rootState.userData.userID,
            }).then((result) => {
                commit('setData', {
                    type: 'voucherList',
                    data: result.data
                })
                resolve(result.data)
            }).catch((error) => {
                resolve(error.response.data);
            })
        })
    },

}
const state = () => {
    return {
        voucherList: {}
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}