const mutations = {
    setData(state, data) {
        state[data.type] = data.data;
    }
}
const actions = {
    mobilityOneTopup({ rootState }, data) {
        return new Promise((resolve) => {
            let form = {...data };
            form.os = rootState.userData.os;
            form.userID = rootState.userData.userID;
            form.beneAcctNo = "0" + form.beneAcctNo;
            rootState.apiaxios.post("/mobilityOneValidateAndReserve", form).then((result) => {
                resolve(result.data);
            }).catch((error) => {
                resolve(error.response.data)
            })
        })
    },
    mobilityOneValidateAndReserve({ rootState }, data){
        return new Promise((resolve) => {
            let form = {...data };
            form.os = rootState.userData.os;
            form.userID = rootState.userData.userID;
            rootState.apiaxios.post("/mobilityOneValidateAndReserve", form).then((result) => {
                resolve(result.data);
            }).catch((error) => {
                resolve(error.response.data)
            })
        })
    }
}
const state = () => {
    return {

    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}