const mutations = {
    SET_RECURRING_DELETE_DATA(state, payload) {
        state.recurring_delete_data = payload;
    },
    SET_SELECTED_RECURRING_DATA(state, payload) {
        state.selected_recurring_data = payload;
    },
    SET_RECURRING_UPDATE_DATA(state, payload) {
        state.recurring_update_data = payload;
    },
    SET_RECURRING_ADD_DATA(state, payload) {
        state.recurring_add_data = payload;
    },
    SET_RECURRING_RESULT(state, payload) {
        state.setupResult = payload;
    },
    SET_RECURRING_TRANSFER_DATA(state, payload) {
        state.recurring_transfer_data = payload;
    },
    SET_RECURRING_FORM_DATA(state, payload) {
        state.recurring_form_data = payload;
    },
    SET_RECURRING_LIST_STATE(state, payload) {
        state.recurring_list_state = payload;
    }
}
const actions = {
    recurringEnquiry({ rootState }) {
        return new Promise((resolve) => {
            rootState.apiaxios.post("/recurringEnquiry", {
                os: rootState.userData.os,
                version: rootState.userData.version,
                userID: rootState.userData.userID,
                recurringID: '',
                funcName: '',
            }).then((result) => {
                resolve(result.data)
            }).catch((error) => {
                resolve(error.response.data);
            })
        })
    },
    standingInstructionEnquiry({ rootState }) {
        return new Promise((resolve) => {
            rootState.apiaxios.post("/standingInstructionEnquiry", {
                os: rootState.userData.os,
                version: rootState.userData.version,
                userID: rootState.userData.userID,
                recurringID: '',
                funcName: '',
            }).then((result) => {
                resolve(result.data)
            }).catch((error) => {
                resolve(error.response.data);
            })
        })
    },
    deleteStandingInstruction({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/deleteStandingInstruction", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    editStandingInstruction({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/editStandingInstruction", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    editRecurring({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/editRecurring", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    accountVerification({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/accountVerification", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    addStandingInstruction({ rootState }, data) {
        return new Promise((resolve) => {
          let form = { ...data };
          form.os = rootState.userData.os;
          form.userID = rootState.userData.userID;
          rootState.apiaxios
            .post("/addStandingInstruction", form)
            .then((result) => {
              resolve(result.data);
            })
            .catch((error) => {
              resolve(error.response.data);
            });
        });
    },
}
const state = () => {
    return {
        recurring_delete_data: null,
        selected_recurring_data: null,
        recurring_update_data: null,
        recurring_add_data: null,
        setupResult: {},
        recurring_transfer_data: null,
        recurring_form_data: null,
        recurring_list_state: null,
    }
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}