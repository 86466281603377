import moment from "moment";
const mutations = {
    setData(state, data) {
        state[data.type] = data.data;
    },
    SET_SELECTED_WALLET_DATA(state, payload) {
        state.selected_wallet_data = payload;
    },
    SET_SELECTED_WALLET_BLOCK_CARD_DATA(state, payload) {
        state.selected_wallet_block_card_data = payload;
    },
    SET_REPLACEMENT_OPTION(state, payload) {
        state.replacement_option = payload;
    },
    SET_DISPUTE_REQUEST_DATA(state, payload) {
        state.dispute_request_data = payload;
    },
    SET_WALLET_CLOSURE_REQUEST_DATA(state, payload) {
        state.wallet_closure_request_data = payload;
    },
    SET_WALLET_APPLY_CARD_DATA(state, payload) {
        state.wallet_apply_new_card_data = payload;
    },
    SET_WALLET_APPLY_PHYSICAL_CARD_DATA(state, payload) {
        state.wallet_apply_physical_card_data = payload;
    },
    SET_WALLET_ADD_CARD_DATA(state, payload) {
        state.wallet_add_card_data = payload;
    },
    SET_WALLET_REMOVE_CARD_DATA(state, payload) {
        state.wallet_remove_card_data = payload;
    },
    SET_WALLET_PRIMARY_CARD_DATA(state, payload) {
        state.wallet_set_primary_card_data = payload;
    },
    SET_WALLET_SEND_MONEY_DATA(state, payload) {
        state.wallet_send_money = payload;
    },
};
const actions = {
    setQRCard({ commit }, data) {
        commit("setData", {
            type: "selectedQRCard",
            data: data,
        });
    },
    doSetCardPIN({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/setCardPIN", payload)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doRemoveOtherCard({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/deleteOffUsCard`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doAddGopayzCard({ commit, rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/addPreEmbossCard`, payload)
                .then((res) => {
                    commit("setData", {
                        type: "addPreEmbossCard",
                        data: res.data,
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doAddOtherCard({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/addOffUsCard`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doSetOtherCardAsPrimary({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/setDefaultOffUsCard`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doSetCardAsPrimary({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/setDefaultCard`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doSetCardAsPrimaryQR({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/setDefaultQRCard`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doApplyPhysicalCard({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/setPhysicalCard`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doActivateCard({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/activatePhysicalCard`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doApplyNewCard({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/applicationCreation`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getCardType({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/availCardType`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doSubmitWalletClosure({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/walletClosure`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doDisputeTransaction({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/disputeTransaction`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getFeeEnquiry({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/feeEnquiry`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    applyPromoCode({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/applyInHousePromoCode`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getPromoCodeList({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/getAvailPromoCodeList`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doReplacementWalletCard({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/cardReplacement`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doBlockWalletCard({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/cardProfileMaintenance`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    doUnblockWalletCard({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post(`/cardProfileMaintenanceUnblock`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },

    getTransferEligibility({ rootState, commit }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            commit("setData", {
                type: "transferRequest",
                data: data,
            });
            rootState.apiaxios
                .post("/walletTransferEligibility", data)
                .then((result) => {
                    resolve(result.data);
                });
        });
    },
    walletTransfer({ rootState }, data) {
        return new Promise((resolve) => {
            data.userID = rootState.userData.userID;
            data.currencyCode = "458";
            data.feeRefNo = "";
            data.os = rootState.userData.os;
            rootState.apiaxios
                .post("/walletTransfer", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    cardEnquiry({ rootState, state, commit }) {
        return new Promise((resolve) => {
            if (
                state.card &&
                Object.keys(state.card).length > 0 &&
                moment(state.getCardTime).diff(moment(), "minutes") <= 1
            ) {
                resolve(state.card);
                return;
            }
            rootState.apiaxios
                .post("/cardEnquiry", {
                    os: rootState.userData.os,
                    userID: rootState.userData.userID,
                })
                .then((result) => {
                    commit("setData", {
                        type: "card",
                        data: result.data,
                    });
                    commit("setData", {
                        type: "getCardTime",
                        data: new Date(),
                    });
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getTransferFavouriteList({ rootState }) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/favouriteEnq", {
                    userID: rootState.userData.userID,
                    serviceCode: "TRF",
                    productGroup: "",
                    os: rootState.userData.os,
                })
                .then((result) => {
                    resolve(result.data);
                });
        });
    },
    getWallet({ rootState, state, commit }, resetCache) { 
        return new Promise((resolve) => {
            if (
                state.wallet &&
                Object.keys(state.wallet).length > 0 &&
                state.walletGetTime &&
                moment(state.walletGetTime).diff(moment(), "minutes") <= 1 &&
                !resetCache
            ) {
                resolve(state.wallet);
                return;
            }
            rootState.apiaxios
                .post("/walletEnquiry", {
                    os: rootState.userData.os,
                    userID: rootState.userData.userID,
                })
                .then((result) => {
                    commit("setData", {
                        type: "wallet",
                        data: result.data,
                    });
                    commit("setData", {
                        type: "walletGetTime",
                        data: new Date(),
                    });
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getAvailPromoCodeList({ rootState, commit }) {
        return new Promise((resolve) => {
            let detail = {
                os: rootState.userData.os,
                version: 1253,
                userID: rootState.userData.userID,
                trxReferenceNo: "",
            };
            rootState.apiaxios
                .post("/getAvailPromoCodeList", detail)
                .then((result) => {
                    commit("setData", {
                        type: "promoCode",
                        data: result.data,
                    });
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    setSelectedCard({ commit }, data) {
        commit("SET_SELECTED_WALLET_DATA", data);
    },
    updateCardEnquiry({ rootState, state }, data) {
        return new Promise((resolve) => {
            let form = {...state.cardUsageCache };
            form.MPIN = data;
            form.os = rootState.userData.os;
            form.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/cardEnquiry", form)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    setCardUsageCache({ commit }, data) {
        commit("setData", {
            type: "cardUsageCache",
            data: data,
        });
    },
    validatePass({ rootState, commit }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/validatePass", {
                    os: rootState.userData.os,
                    userID: rootState.userData.userID,
                    password: data.password,
                    updInfo: data.updInfo,
                })
                .then((result) => {
                    commit("setData", {
                        type: "setPin",
                        data: result.data,
                    });
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    setCardPIN({ rootState, state }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/setCardPIN", {
                    os: rootState.userData.os,
                    userID: rootState.userData.userID,
                    cardPIN: data.cardPIN,
                    confirmCardPIN: data.confirmCardPIN,
                    uniqueRef: state.setPin.uniqueRef != undefined ? state.setPin.uniqueRef : state.wallet_add_card_data.uniqueRef,
                    lastUpdateTMS: state.getCardTime.lastUpdateTMS != undefined ? state.getCardTime.lastUpdateTMS : state.wallet_add_card_data.lastUpdateTMS,
                    URN: state.URN != undefined ? state.URN : state.wallet_add_card_data.URN,
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    setPrimaryCard({ rootState, state }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/setDefaultCard", {
                    os: rootState.userData.os,
                    userID: rootState.userData.userID,
                    MPIN: data.MPIN,
                    lastUpdateTMS: state.getCardTime.lastUpdateTMS,
                    URN: state.URN,
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    setPrimaryQRCard({ rootState, state }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/setDefaultQRCard", {
                    os: rootState.userData.os,
                    userID: rootState.userData.userID,
                    MPIN: data.MPIN,
                    lastUpdateTMS: state.getCardTime.lastUpdateTMS,
                    URN: state.URN,
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getTransferQR({ rootState }, data) {
        return new Promise((resolve) => {
            data.userID = rootState.userData.userID;
            data.staticQR = "N";
            data.os = rootState.userData.os;
            rootState.apiaxios
                .post("/walletTransferGenerateQR", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    checkQRStatus({ rootState }, data) {
        return new Promise((resolve) => {
            data.userID = rootState.userData.userID;
            data.os = rootState.userData.os;
            rootState.apiaxios
                .post("/walletTransferQRStatus", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getOtherPaymentTransactionHistory({rootState}, data) {
        return new Promise((resolve,) => {
            data.userID = rootState.userData.userID;
            data.os = rootState.userData.os;
            rootState.apiaxios
            .post('/offUsCardTransactionHistory', data)
            .then((result) => {
                resolve(result.data);
            })
            .catch((error) => {
                resolve(error.response.data);
            });
        });
    },
    transactionHistory({ rootState }, data) {
        return new Promise((resolve) => {
            data.userID = rootState.userData.userID;
            data.os = rootState.userData.os;
            rootState.apiaxios
                .post("/transactionHistory", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getTransactionStatements({ rootState }) {
        return new Promise((resolve) => {
            const data = {
                os: rootState.userData.os,
                userID: rootState.userData.userID,
                sqlId: "IRB01_STMT_DT",
            };
            rootState.apiaxios
                .post("/dataEnq", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getWalletTransferPendingList({ rootState }) {
        return new Promise((resolve) => {
            const data = {
                os: rootState.userData.os,
                userID: rootState.userData.userID,
            };
            rootState.apiaxios
                .post("/walletTransferPendingList", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getTransferHistoryDetails({ rootState }, data) {
        return new Promise((resolve) => {
            data.userID = rootState.userData.userID;
            data.os = rootState.userData.os;
            rootState.apiaxios
                .post("/transactionHistoryDetails", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getTownList({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios.post(`/dataEnq`, payload).then((res) => {
                resolve(res);
            });
        });
    },
    getBank({ rootState, commit }) {
        return new Promise((resolve) => {
            if (
                state.bank &&
                Object.keys(state.bank).length > 0 &&
                moment(state.getBankTime).diff(moment(), "minutes") <= 10
            ) {
                resolve(state.wallet);
                return;
            }
            rootState.apiaxios
                .post("/dataEnq", {
                    os: rootState.userData.os,
                    sqlId: "AG019_BNK_CDE",
                    userID: rootState.userData.userID,
                })
                .then((result) => {
                    commit("setData", {
                        type: "bank",
                        data: result.data,
                    });
                    commit("setData", {
                        type: "getBankTime",
                        data: new Date(),
                    });
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    postWalletTopup({ rootState }, data) {
        return new Promise((resolve) => {
            data.userID = rootState.userData.userID;
            data.os = rootState.userData.os;
            data.mobileNo = rootState.userData.userID;
            rootState.apiaxios
                .post("/walletTopUp", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getProfileEnquiry({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios.post(`/custProfileEnquiry`, payload).then((res) => {
                resolve(res);
            });
        });
    },
    getWalletEnquiryBlock({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/dataEnq", payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getWalletEnquiry({ rootState }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/walletEnquiry", payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getCardEnquiry({ rootState, commit }) {
        return new Promise((resolve) => {
            const payload = {
                os: rootState.userData.os,
                userID: rootState.userData.userID,
            };
            rootState.apiaxios
                .post("/cardEnquiry", payload)
                .then((res) => {
                    commit("setData", {
                        type: "card_enquiry_data",
                        data: res.data,
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getOtherCardEnquiry({ rootState, commit }, payload) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/offUsCardEnquiry", payload)
                .then((res) => {
                    commit("setData", {
                        type: "other_card_enquiry_data",
                        data: res.data,
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    walletClosure({ rootState, commit, state }, data) {
        return new Promise((resolve) => {
            const obj = {...state.tempClosureCache };
            obj.MPIN = data;
            obj.os = rootState.userData.os;
            obj.esFileWS = obj.esFileWSObj[0].response.esFileWS;
            obj.fileType = obj.esFileWSObj[0].data.documentType;
            obj.esFileWS2 = obj.esFileWS2Obj[0].response.esFileWS2;
            obj.fileType2 = obj.esFileWS2Obj[0].data.documentType;
            delete obj.esFileWSObj;
            delete obj.esFileWS2Obj;
            rootState.apiaxios
                .post("/walletClosure", obj)
                .then((result) => {
                    commit("setData", {
                        type: "tempClosureCache",
                        data: null,
                    });
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    transactionFeeEnquiry({ rootState, commit }, data) {
        return new Promise((resolve) => {
            data.userID = rootState.userData.userID;
            data.os = rootState.userData.os;
            commit("setData", {
                type: "topupCache",
                data: data,
            });
            rootState.apiaxios
                .post("/transactionFeeEnquiry", data)
                .then((result) => {
                    commit("setData", {
                        type: "topupResponseCache",
                        data: result.data,
                    });
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    setTopupCache({ commit }, data) {
        commit("setData", {
            type: "topupCache",
            data: data,
        });
    },
    walletTopUp({ rootState }, data) {
        return new Promise((resolve) => {
            data.userID = rootState.userData.userID;
            data.os = rootState.userData.os;
            data.mobileNo = rootState.userData.userID;
            data.emailAddress = rootState.userData.emailAddress;
            rootState.apiaxios
                .post("/walletTopUp", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    getOffUsCardEnquiry({ rootState }) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/offUsCardEnquiry", {
                    userID: rootState.userData.userID,
                    os: rootState.userData.os,
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    addFavourite({ rootState, state }, desc) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/addFavourite", {
                    userID: rootState.userData.userID,
                    os: rootState.userData.os,
                    serviceCode: "TRF",
                    productCode: "TRF",
                    productGrp: state.transferUser.RecipientName,
                    refNo: state.transferRequest.transferMobileNo,
                    desc,
                    ref1: null,
                    ref2: "FavMobileNumber",
                    ref3: null,
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    walletWithdrawal({ rootState, state }, data) {
        return new Promise((resolve) => {
            let form = {...state.transferCache };
            form.MPIN = data;
            form.os = rootState.userData.os;
            form.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/walletCashWithdrawal", form)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    walletWithdrawFee({ rootState }, data) {
        return new Promise((resolve) => {
            let form = {...data };
            form.os = rootState.userData.os;
            form.userID = rootState.userData.userID;
            form.pymtMode = "OX";
            rootState.apiaxios
                .post("/preFeeEnquiry", form)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    setTransferCache({ commit }, data) {
        commit("setData", {
            type: "transferCache",
            data: data,
        });
    },
    downloadStatement({ rootState }, data) {
        return new Promise((resolve) => {
            let form = {...data };
            form.os = rootState.userData.os;
            form.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/statementDownload", form)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    cardCVV2Enquiry({ rootState, state, commit }, MPIN) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/cardCVV2Enquiry", {
                    os: rootState.userData.os,
                    userID: rootState.userData.userID,
                    MPIN: MPIN,
                    URN: state.selected_wallet_data.URN,
                })
                .then((result) => {
                    commit("setData", {
                        type: "selected_wallet_detail",
                        data: result.data,
                    });
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    generateQRPayload({ rootState }, data) {
        return new Promise((resolve) => {
            rootState.apiaxios
                .post("/generateQRPayload1", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    transactionTypeOptInOutEnquiry({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/transactionTypeOptInOutEnquiry", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
    transactionTypeOptInOut({ rootState }, data) {
        return new Promise((resolve) => {
            data.os = rootState.userData.os;
            data.userID = rootState.userData.userID;
            rootState.apiaxios
                .post("/transactionTypeOptInOut", data)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    resolve(error.response.data);
                });
        });
    },
};
const state = () => {
    return {
        transferRequest: {},
        transferUser: {},
        wallet: {},
        walletGetTime: new Date("1970-01-01Z00:00:00:000"),
        card: {},
        promoCode: {},
        setPin: {},
        getCardTime: new Date("1970-01-01Z00:00:00:000"),
        transferCache: {},
        tempClosureCache: {},
        topupCache: {},
        topupResponseCache: {},
        addPreEmbossCard: {},
        // store temporary data
        selected_wallet_data: null,
        selected_wallet_detail: null,
        selected_wallet_block_card_data: null,
        replacement_option: false,
        dispute_request_data: null,
        wallet_closure_request_data: null,
        wallet_apply_new_card_data: null,
        wallet_apply_physical_card_data: null,
        wallet_add_card_data: null,
        wallet_remove_card_data: null,
        wallet_set_primary_card_data: null,
        wallet_send_money: null,
        card_enquiry_data: null,
        other_card_enquiry_data: null,
        other_card_enquiry_tab_is_open: false,
        selectedQRCard: null,
        cardUsageCache: null,
        cardUsageParams: "",
        redirectPayment: false,
        wallet_replacement_with_physical_card_data: null,
    };
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};