import config from "@/config.json";
import axios from "axios";
import index from "./../index";

const mutations = {
  setData(state, data) {
    state[data.type] = data.data;
  },
  setGlobalErrorCode(state, data) {
    index.commit("setData", {
      type: "globalErrorCode",
      data: data,
    });
  },
};
const actions = {
  updateLoginUser({ commit }, data) {
    let form = { ...data };
    form.position = form.positionHeld;
    form.occupationType = form.occupation;
    commit("setData", {
      type: "profile",
      data: form,
    });
    commit("setData", {
      type: "updatingUser",
      data: true,
    });
  },
  cacheLoginData({ commit }, data) {
    commit("setData", {
      type: "login",
      data: data,
    });
  },
  custProfileUpdate({ rootState, state, commit }, MPIN) {
    return new Promise((resolve) => {
      let data = { ...state.profile };
      data.userID = rootState.userData.userID;
      data.MPIN = MPIN;
      data.os = rootState.userData.os;
      rootState.apiaxios
        .post("/custProfileUpdate", data)
        .then((result) => {
          resolve(result.data);
          commit("setData", {
            type: "updatingUser",
            data: false,
          });
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  login({ rootState, commit, state, dispatch }, data) {
    return new Promise((resolve) => {
      commit("setData", {
        type: "login",
        data: { ...data },
      });
      data.os = rootState.userData.os;
      rootState.apiaxios
        .post("/consumerLogin", data)
        .then((result) => {
          result.data.phoneNumber =
            !result.data.userID && state.loginUser.phoneNumber
              ? state.loginUser.phoneNumber
              : result.data.phoneNumber;
          commit("setData", {
            type: "loginUser",
            data: result.data,
          });
          if (result.data.token) {
            const a = axios.create({
              baseURL: config.apiUrl,
              headers: {
                Authorization: result.data.token
                  ? "Bearer " + result.data.token
                  : null,
                Lang: rootState.selectedLang,
                PartnerID: rootState.userData.partnerID,
              },
            });
            dispatch('getAxiosResponse', { data: a }, { root: true });
          }
          let userData = rootState.userData;
          userData.responseCode = "000000";
          commit(
            "setData",
            { type: "userData", data: userData },
            { root: true }
          );
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  logout({ commit, rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/logout", {
          userID: rootState.userData.userID,
          os: rootState.userData.os,
        })
        .then((result) => {
          if (result.data.responseCode === "000000") {
            commit("setData", {
              type: "loginUser",
              data: {},
            });
            commit(
              "wallet/setData",
              {
                type: "wallet",
                data: {},
              },
              {
                root: true,
              }
            );
            commit(
              "wallet/setData",
              {
                type: "walletGetTime",
                data: new Date("1970-01-01Z00:00:00:000"),
              },
              {
                root: true,
              }
            );
            commit(
              "wallet/setData",
              {
                type: "getCardTime",
                data: new Date("1970-01-01Z00:00:00:000"),
              },
              {
                root: true,
              }
            );
            commit(
              "wallet/setData",
              {
                type: "card",
                data: {},
              },
              {
                root: true,
              }
            );
            commit("setData", {
              type: "loginUser",
              data: {},
            });
            commit("setData", {
              type: "profile",
              data: {},
            });
          } else {
             if(result.data.responseCode== "503"){
             commit(
              "setData",
              {
                type: "setMaintenance",
                data: result.data,
              },
              {
                root: true,
              }
            )}
            commit(
              "setData",
              {
                type: "setGlobalError",
                data: result.data.responseDescription,
              },
              {
                root: true,
              }
            );
          }
          resolve(result.data);
        })
        .catch((error) => {
          if (error.response.data.responseCode === "001310") {
            commit("setData", {
              type: "loginUser",
              data: {},
            });
            commit(
              "wallet/setData",
              {
                type: "wallet",
                data: {},
              },
              {
                root: true,
              }
            );
            commit(
              "wallet/setData",
              {
                type: "walletGetTime",
                data: new Date("1970-01-01Z00:00:00:000"),
              },
              {
                root: true,
              }
            );
            commit(
              "wallet/setData",
              {
                type: "getCardTime",
                data: new Date("1970-01-01Z00:00:00:000"),
              },
              {
                root: true,
              }
            );
            commit(
              "wallet/setData",
              {
                type: "card",
                data: {},
              },
              {
                root: true,
              }
            );
            commit("setData", {
              type: "loginUser",
              data: {},
            });
            commit("setData", {
              type: "profile",
              data: {},
            });
          }
          resolve(error.response.data);
        });
    });
  },
  setLoginID({ commit, rootState, dispatch }, data) {
    rootState.userData.userID = "60" + data.phoneNumber;
    return new Promise((resolve) => {
      dispatch("validateUser", "60" + data.phoneNumber)
        .then((result) => {
          if (result.responseCode === "000000") {
            commit("setData", {
              type: "loginUser",
              data: data,
            });
            commit(
              "setData",
              {
                type: "userData",
                data: rootState.userData,
              },
              { root: true }
            );
            resolve();
          } else {
            resolve(result);
          }
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  validateUser({ rootState }, phone) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/validateUser", {
          userID: phone,
          os: rootState.userData.os,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  validateUserData({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/validateUser", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  validateForgotPassword({ rootState }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/validateFgtPwdReq", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getProfile({ commit, rootState, state }) {
    return new Promise((resolve) => {
      if (state.updatingUser) {
        resolve(state.profile);
        return;
      }
      rootState.apiaxios
        .post("/custProfileEnquiry", {
          userID: rootState.userData.userID,
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "profile",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getNotifications({ rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/retrieveNotification", {
          userID: rootState.userData.userID,
          os: rootState.userData.os,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getNotificationDetail({ rootState }, data) {
    return new Promise((resolve) => {
      data.userID = rootState.userData.userID;
      data.os = rootState.userData.os;
      rootState.apiaxios
        .post("/viewNotification", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  deleteNotification({ rootState }, data) {
    return new Promise((resolve) => {
      data.userID = rootState.userData.userID;
      data.os = rootState.userData.os;
      rootState.apiaxios
        .post("/deleteNotification", data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  validatePass({ rootState, commit }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/validatePass", {
          userID: rootState.userData.userID,
          password: data.password,
          updInfo: data.updInfo,
          os: rootState.userData.os,
        })
        .then((result) => {
          commit("setData", {
            type: "changePass",
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          commit("setData", {
            type: "changePass",
            data: {},
          });
          resolve(error.response.data);
        });
    });
  },
  changePass({ rootState, state }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/changePass", {
          userID: rootState.userData.userID,
          newPassword: data.newPassword,
          confirmPassword: data.confirmPassword,
          uniqueRef: state.changePass.uniqueRef,
          os: rootState.userData.os,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  changeGoPIN({ rootState, state }, data) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/setupMPIN", {
          userID: rootState.userData.userID,
          MPIN: data.MPIN,
          confirmMPIN: data.confirmMPIN,
          uniqueRef: state.changePass.uniqueRef,
          os: rootState.userData.os,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  getUploadDocumentEligibility({ rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/uploadDocumentEligibility", {
          userID: rootState.userData.userID,
          os: rootState.userData.os,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  manageDevices({ rootState, dispatch }, MPIN) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/consumerDeviceEnq ", {
          userID: rootState.userData.userID,
          MPIN: MPIN,
          os: rootState.userData.os,
        })
        .then((result) => {
          dispatch("setDeviceData", {
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          dispatch("setDeviceData", {
            data: {},
          });
          resolve(error.response.data);
        });
    });
  },
  addDeleteDeviceList({ commit }, list) {
    commit("setData", {
      type: "deleteDevices",
      data: list,
    });
  },
  deleteDeviceList({ rootState, state, commit }, MPIN) {
    return new Promise((resolve) => {
      const data = {
        userID: rootState.userData.userID,
        appUniqueKeys: state.deleteDevices.map((x) => x.appUniqueKey),
        MPIN: MPIN,
        deviceTyps: state.deleteDevices.map((x) => x.deviceTyp),
        os: rootState.userData.os,
      };
      rootState.apiaxios
        .post("/deleteMultipleConsumerDevice ", data)
        .then((result) => {
          let filtered = [];
          state.deleteDevices?.forEach((y) => {
            filtered = state.devices?.data?.data?.filter(
              (x) => x.appUniqueKey != y.appUniqueKey
            );
          });
          commit("setData", {
            type: "devices",
            data: { data: filtered },
          });
          resolve(result.data);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  setDeviceData({ commit }, data) {
    commit("setData", {
      type: "devices",
      data: data,
    });
  },
  changeMobileNo({ rootState, dispatch, state }, newMobile) {
    return new Promise((resolve) => {
      const data = {
        userID: rootState.userData.userID,
        mobileNo: rootState.userData.userID,
        newMobileNo: newMobile,
        uniqueRef: state.loginUser.uniqueRef,
        os: rootState.userData.os,
      };
      rootState.apiaxios
        .post("/changeMobileNo ", data)
        .then((result) => {
          dispatch("setDeviceData", {
            data: result.data,
          });
          resolve(result.data);
        })
        .catch((error) => {
          dispatch("setDeviceData", {
            data: {},
          });
          resolve(error.response.data);
        });
    });
  },
  updateUploadDocument({ commit }, data) {
    commit("setData", {
      type: "uploadDocumentCache",
      data: data,
    });
  },
  uploadDocument({ rootState, state, commit }, MPIN) {
    return new Promise((resolve) => {
      const obj = { ...state.uploadDocumentCache };
      obj.MPIN = MPIN;
      obj.os = rootState.userData.os;
      obj.userID = rootState.userData.userID;
      rootState.apiaxios
        .post("/uploadDocumentConf", obj)
        .then((result) => {
          commit("setData", {
            type: "uploadDocumentCache",
            data: {},
          });
          resolve(result.data);
        })
        .catch((error) => {
          commit("setData", {
            type: "uploadDocumentCache",
            data: {
              uniqueRef: state.uploadDocumentCache.uniqueRef,
            },
          });
          resolve(error.response.data);
        });
    });
  },
  forgotPassword({ rootState }, payload) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post(`/validateFgtPwdReq`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },
  AchgAgeInd({ rootState }) {
    return new Promise((resolve) => {
      rootState.apiaxios
        .post("/acceptanceToChgAgeInd ", {
          userID: rootState.userData.userID,
          acceptance: "Y",
          os: rootState.userData.os,
        })
        .then((result) => {
          resolve(result.data);
        });
    });
  },
};
const state = () => {
  return {
    loginUser: {},
    profile: {},
    changePass: {},
    devices: {},
    deleteDevices: [],
    uploadDocumentCache: {},
    updatingUser: false,
    login: {},
  };
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
